<script setup lang="ts">
import type { NavItem } from '@nuxt/content/dist/runtime/types';

const navigation = inject<Ref<NavItem[]>>('navigation', ref([]));

const links = [{
    label: 'Docs',
    to: '/docs',
}, {
    label: 'Pricing',
    to: '/pricing',
}, {
    label: 'Blog',
    to: '/blog',
}];

const authStore = useAuthStore();
const email = computed(() => authStore.email);
const loading = ref(true);
onMounted(() => {
    authStore.fetchMyUser().finally(() => loading.value = false);
});
</script>

<template>
    <UHeader :links="links">
        <template #logo>
            Showyourwork <UBadge
                label="SaaS"
                variant="subtle"
                class="mb-0.5"
            />
        </template>

        <template
            v-if="!email && !loading"
            #right
        >
            <UButton
                label="Sign in"
                color="gray"
                to="/login"
            />
            <UButton
                label="Sign up"
                icon="i-heroicons-arrow-right-20-solid"
                trailing
                color="black"
                to="/signup"
                class="hidden lg:flex"
            />
        </template>
        <template
            v-else-if="email && !loading"
            #right
        >
            <div class="mr-4 text-sm text-gray-600">
                {{ email }}
            </div>

            <UButton
                label="Dashboard"
                color="gray"
                to="/admin"
            />
            <UButton
                label="Sign out"
                color="gray"
                @click="authStore.logout()"
            />
        </template>

        <template #panel>
            <UNavigationTree
                :links="mapContentNavigation(navigation)"
                default-open
            />
        </template>
    </UHeader>
</template>
