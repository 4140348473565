import axios from 'axios';
import { useAuthStore } from '~/stores/auth';

function isAccessTokenExpired() {
    // Check if the JWT access token is expired
    const authStore = useAuthStore();
    const accessToken = authStore.accessToken;
    if (!accessToken) {
        return true;
    }
    const jwt = accessToken.split('.')[1];
    const jwtObject = JSON.parse(atob(jwt));
    const expirationDate = new Date(jwtObject.exp * 1000);
    return expirationDate < new Date();
}

function isTryingToRefreshTokens(url: string) {
    return url === 'auth/token/refresh/';
}

export default defineNuxtPlugin((_nuxtApp) => {
    const config = useRuntimeConfig();
    axios.defaults.baseURL = config.public.apiBaseUrl;
    // https://github.com/jazzband/djangorestframework-simplejwt/issues/71#issuecomment-711443984
    // https://github.com/axios/axios
    axios.defaults.withCredentials = true;

    axios.interceptors.request.use(async (requestConfig) => {
        const authStore = useAuthStore();
        if (isAccessTokenExpired() && !isTryingToRefreshTokens(requestConfig.url ?? '')) {
            await authStore.fetchAccessTokenFromRefreshToken();
        }
        if (authStore.accessToken && requestConfig.headers) {
            requestConfig.headers.Authorization = `Bearer ${authStore.accessToken}`;
        }
        return requestConfig;
    }, (error) => {
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
        return response.data ?? response;
    }, (error) => {
        const originalRequest = error.config;
        if (isTryingToRefreshTokens(error.config.url)) {
            // To avoid infinite loop, the refresh url can not be retried
            originalRequest.retry = true;
        }
        if (error.response && [401, 403].includes(error.response.status) && !originalRequest.retry) {
            originalRequest.retry = true;
            const authStore = useAuthStore();
            // The whole point of this part is that : if a request fail because of an expired access token,
            // we try to refresh the access token and then retry the original request.
            // But if there is no access token, it means the user is disconnected => no need to try refreshing the token
            if (!authStore.accessToken) {
                return Promise.reject(error);
            }
            return authStore.fetchAccessTokenFromRefreshToken().then((accessToken: string) => {
                if (!accessToken) {
                    return Promise.reject(error);
                }
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                return axios(originalRequest);
            });
        }
        return Promise.reject(error);
    });
});
