<script setup lang="ts">
const colorMode = useColorMode();

const color = computed(() => colorMode.value === 'dark' ? '#111827' : 'white');

useHead({
    meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { key: 'theme-color', name: 'theme-color', content: color },
    ],
    link: [
        { rel: 'icon', href: '/favicon.ico' },
    ],
    htmlAttrs: {
        lang: 'en',
    },
});

const title = 'ShowYour.Work: Feature Announcements for Developers';
const description = 'Show your hard work! Effortlessly create and manage announcements for your project\'s new features with seamless GitHub integration and intelligent commit analysis.';

useSeoMeta({
    title,
    description,
    ogTitle: title,
    ogDescription: description,
    ogImage: 'https://dashboard-template.nuxt.dev/social-card.png',
    twitterImage: 'https://dashboard-template.nuxt.dev/social-card.png',
    twitterCard: 'summary_large_image',
});
</script>

<template>
    <div>
        <NuxtLoadingIndicator />

        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>

        <UNotifications />
        <UModals />
    </div>
</template>
